<header class="header-area fotolia-header header_1">
    <div class="topbar">
        <div class="container">
            <div class="row">
                <div class="col-md-7">
                    <div class="tb-contact-info">
                        <div class="phone-mail">
                            <span><i class="fa fa-phone" aria-hidden="true"></i><a class="email-align"
                                    href="tel:9393769999">(+91)
                                    9393769999</a></span>
                            <span><i class="fa fa-envelope" aria-hidden="true"></i><a
                                    href="mailto:events@pramaangroup.com" class="__cf_email__ email-align"
                                    data-cfemail="6703080a060e0927060303150214144904080a">events@pramaangroup.com</a></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="tb-contact-info">
                        <div class="location">
                            <span class="email-align"><i class="fa fa-map-marker" aria-hidden="true"></i>Telangana &
                                Andhra Pradesh</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="voidmega-header">
        <div class="container">
            <div class="bgvm">
                <div class="row">
                    <div class="col-12 col-xl-10">
                        <div class="vmm-header header-transparent-on vmm-mega-menu mega-menu-fullwidth header-align">
                            <div class="container">

                                <div class="vmm-header-container">

                                    <div class="logo header-logo-aign" data-mobile-logo="assets/logo/pramaan-logo.png"
                                        data-sticky-logo="assets/logo/pramaan-logo.png">
                                        <a routerLink=""><img src="assets/logo/pramaan-logo.png" width="70px"
                                                alt="logo" /></a>
                                    </div>

                                    <div class="burger-menu">
                                        <div class="line-menu line-half first-line">
                                        </div>
                                        <div class="line-menu"></div>
                                        <div class="line-menu line-half last-line">
                                        </div>
                                    </div>

                                    <nav class="vmm-menu menu-caret submenu-scale">
                                        <ul>
                                            <li class="mega-menu"><a (click)="scrollFunction('home')"
                                                    class="pointer">Home</a>
                                            </li>
                                            <li class="mega-menu"><a (click)="scrollFunction('about')"
                                                    class="pointer">About
                                                    Us</a>
                                            </li>
                                            <li class="mega-menu">
                                                <a (click)="scrollFunction('services')" class="pointer">Services</a>
                                            </li>
                                            <li class="mega-menu">
                                                <a (click)="scrollFunction('gallery')" class="pointer">Gallery</a>

                                            </li>
                                            <li><a (click)="scrollFunction('contacts')" class="pointer">Contact
                                                    Us</a></li>
                                        </ul>
                                    </nav>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-none col-xl-2 d-xl-block">
                        <div class="search-menu-btn">
                            <div class="searchV1-btn">
                                <div class="soc-btn">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- <app-header></app-header> -->
<!-- <div *ngIf="!loader">
    <div id="global-loader">
    <img src="assets/img/loader.svg" alt="loader">
    </div>
</div> -->
<app-preloader *ngIf="!loader"></app-preloader>

<div *ngIf="loader">
    <div class="hero full-height jarallax home-jarallax-bgvid" #myElement id="home">
        <div class="wrapper opacity-mask d-flex align-items-center justify-content-center text-center animate_hero"
            data-opacity-mask="rgba(0, 0, 0, 0.5)">
            <div class="container">
                <div class="hero-content">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="hero-text" style="margin-top:30px">
                                <h2 class="wow fadeIn slide-header" data-wow-delay=".5s">
                                    <span>“An event is just not an experience, but a memory in the making”</span>
                                </h2>
                                <p class="wow fadeIn" data-wow-delay=".99s">With Pramaan group, every event becomes an
                                    extraordinary experience</p>
                            </div>
                            <div class="hb-scroll-btn">
                                <a (click)="scrollFunction('about')" class="smoothscroll"
                                    style="font-size: 30px;cursor: pointer;"><i class="fa fa-angle-double-down"
                                        aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<section id="about" class="about-area about-1 section-padding" style="padding-bottom: 0px ">
    <div class="container">
        <div class="section-titleV1 text-center">
            <h3 class="wow fadeInUp" data-wow-delay=".25s">ABOUT US</h3>
            <p class="wow fadeInUp" data-wow-delay=".15s">Why Choose Pramaangroup Events</p>

        </div>
        <div class="row">
            <div class="col-md-6 col-sm-6 d-flex align-items-center">
                <div class="about-img wow fadeInLeft text-center" data-wow-delay=".25s">
                    <img id="img1" src="../../../assets/uploads/about-1.jpg" width="100%" alt>
                    <img id="img2" src="../../../assets/uploads/about-mobile.jpg" width="100%" alt="">
                </div>
            </div>
            <div class="col-md-6">
                <div class="about-text">
                    <div class="section-text">
                        <p class="wow fadeInUp" data-wow-delay=".35s">Events are an opportunity to connect and
                            celebrate. An event is measured by the number of smiles it spreads. We celebrate events as
                            they create lasting memories that can be cherished for a lifetime.Memories can linger on if
                            an event is planned in a right way and with the right people.
                        </p>
                        <ul>
                            <li class="wow fadeInUp" data-wow-delay=".45s">"At Pramaan group, we meticulously handle
                                each event that we undertake. We understand your requirements and budget and try to
                                deliver superior service, without compromising on the quality."
                                Our team of experienced
                                and skilled professionals put their heart and soul into making your special event worth
                                remembering a lifetime."
                            </li>
                        </ul>
                        <a routerLink="/about" class="btn-style-1 wow fadeInUp" data-wow-delay=".99s">Read
                            More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="service-area section-padding" id="services">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-titleV1">
                    <h3 class="wow fadeInUp" data-wow-delay=".25s">Our Works</h3>
                    <p class="wow fadeInUp" data-wow-delay=".15s">We make your events smart & impactful by personalised
                        event management services</p>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <article class="card card--1" style="margin:10px">
                    <div class="card__img"></div>
                    <a routerLink="/past-events" class="card_link">
                        <div class="card__img--hover"></div>
                        <div class="card__info">
                            <h3 class="card__title upcoming-event">
                                Past Events Works</h3>
                        </div>
                    </a>
                </article>
            </div>
            <div class="col-md-6">
                <article class="card card--2" style="margin:10px">
                    <div class="card__img"></div>
                    <a routerLink="/upcoming-events" class="card_link">
                        <div class="card__img--hover"></div>
                        <div class="card__info">

                            <h3 class="card__title upcoming-event">
                                Upcoming Events Works
                            </h3>
                        </div>
                    </a>

                </article>
            </div>
        </div>
    </div>
</section>


<section class="testimonial-area section-padding">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-titleV1">
                    <h3 class="wow fadeInUp" data-wow-delay=".25s">Testimonials </h3>
                    <p class="wow fadeInUp" data-wow-delay=".15s">clients feedbacks</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="three-review-area">
                    <div class="container">
                        <div class="three-review-slider owl-theme owl-carousel">
                            <div class="review-item">
                                <i class='bx bxs-quote-left'></i>
                                <h2>BIG heartfelt thanks to you for all these amazing captures in time! These will be
                                    cherished forever..Thank you so much for capturing the day so perfectly! It is
                                    incredible.
                                    Hopefully we will get to work with you again in the future!</h2>
                                <p>All the best to you and your team.</p>
                                <div class="review-bottom">
                                    <!--    <img src="assets/img/home-three/review.png" alt="Review"> -->
                                    <h3>Vijayalakshmi</h3>
                                    <!-- <span>Policy Advisor</span> -->
                                </div>
                            </div>

                            <div class="review-item">
                                <i class='bx bxs-quote-left'></i>
                                <h2>Photoshoot & Everything went Really well.thanks to you and your team & extremely
                                    grateful to get the copies at the earliest</h2>
                                <div class="review-bottom">
                                    <!-- <img src="assets/img/home-three/review.png" alt="Review"> -->
                                    <h3>Manoj</h3>
                                    <!-- <span>Policy Advisor</span> -->
                                </div>
                            </div>

                            <div class="review-item">
                                <i class='bx bxs-quote-left'></i>
                                <h2>Excellent photography., truly professional team with peaks of patience. We never
                                    know when they clicks the shot but after releasing the teaser/final images,
                                    certainly "WOW" feel will be out from the customers. As a loyal customer, I would
                                    strongly recommend to all my friends and family to go with PRAMAAN Photography.</h2>
                                <div class="review-bottom">
                                    <!-- <img src="assets/img/home-three/review.png" alt="Review"> -->
                                    <h3>Sreekanth</h3>
                                    <!-- <span>Policy Advisor</span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>






<div class="core-service-area ptb-100" id="gallery" style="padding-top: 10px;">
    <div class="container">
        <div class="section-titleV1">
            <h3 class="wow fadeInUp" data-wow-delay=".25s" style="padding-top: 25px;">GALLERY</h3>
            <p class="wow fadeInUp" data-wow-delay=".15s">Our recent works</p>
        </div>
        <!--- Dummy Copy --->
        <div class="core-service-list-tab" *ngIf="!errorMessage">
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active text-center" id="core-service-1" role="tabpanel">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-4" *ngFor="let item of mainHomeGallery">
                            <figure class="single-shuffle">
                                <div class="aspect">
                                    <div class="aspect__inner ssf-content wow zoomIn" data-wow-delay=".15s">
                                        <img src="https://drive.google.com/uc?id={{item.id}}" alt />
                                        <div class="ssf-hover">
                                            <a data-fancybox="group-4" class="fancyGallery"
                                                href="https://drive.google.com/uc?id={{item.id}}">
                                                <i class="fa fa-search-plus" aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </figure>
                        </div>
                    </div>
                    <button (click)="viewMore()">View More</button>
                </div>
            </div>
        </div>
        <div class="row justify-content-center" *ngIf="errorMessage">
            <div class="col-md-12" style="display:contents">
                <img src="../../../assets/images/error-img.png">
            </div>
        </div>
    </div>
</div>

<div class="bg-white">
    <div class="container margin_120_95" id="contacts">
        <div class="row justify-content-between">
            <div class="section-titleV1">
                <h3 class="wow fadeInUp leave-msg" data-wow-delay=".25s">CONTACT US</h3>
                <p class="wow fadeInUp" data-wow-delay=".15s">Get in touch to create some of the best memories</p>
            </div>
            <div class="col-xl-6">
                <div class="contacts_wrapper">
                    <div data-cue="slideInUp">
                        <div class="title text-center">
                            <h2>Telangana</h2>
                        </div>
                        <div class="d-flex">
                            <i class="fa fa-map-marker map-icon"></i>
                            <p class="contact-icons">304K, Road Num : 78, Jubilee Hills, Hyderabad -
                                500096</p>
                        </div>
                        <br>
                        <div class="phone_element no_borders"><a href="tel:9393769999"><i
                                    class="fa fa-phone contact-icons phone-icon"></i><span class="contact-field">Mobile:
                                    (+91)
                                    9393769999</span></a>
                        </div>

                        <div class="phone_element no_borders"><a href="mailto:pramaanphotography@gmail.com"><i
                                    class="fa fa-envelope contact-icons phone-icon"></i><span
                                    class="contact-field">Email:
                                    events@pramaangroup.com</span></a>
                        </div>
                        <br>
                        <div class="map_contact">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15224.675111832981!2d78.38203308616943!3d17.45163412007793!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x558c1167456aea43!2sPRAMAAN%20Photography!5e0!3m2!1sen!2sin!4v1616244011831!5m2!1sen!2sin"
                                style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="contacts_wrapper">
                    <div data-cue="slideInUp">
                        <div class="title text-center">
                            <h2>Andhra Pradesh</h2>
                        </div>
                        <div class="d-flex">
                            <i class="fa fa-map-marker map-icon"></i>
                            <p class="contact-icons"> #40-5-16, DV Manor Road, Above Mahendra
                                Jewellery Opp. F3
                                Manor
                                Food
                                Plaza, Tikkle Rd, Vijayawada, Andhra Pradesh 520010</p>
                        </div>

                        <div class="phone_element no_borders"><a href="tel:9393769999"><i
                                    class="fa fa-phone contact-icons phone-icon"></i><span class="contact-field">Mobile:
                                    (+91)
                                    9393769999</span></a>
                        </div>
                        <div class="phone_element no_borders" style="margin-bottom: 24px;"><a
                                href="mailto:pramaanphotography@gmail.com"><i
                                    class="fa fa-envelope contact-icons phone-icon"></i><span
                                    class="contact-field">Email:
                                    events@pramaangroup.com</span></a>
                        </div>
                        <div class="map_contact">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15301.890223802722!2d80.648526!3d16.502224!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x15eea369d62d262c!2sPRAMAAN%20Photography!5e0!3m2!1sen!2sin!4v1614594515455!5m2!1sen!2sin"
                                style="border:0;" allowfullscreen="" loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-between">
            <div class="section-titleV1">
                <h3 class="wow fadeInUp leave-msg" data-wow-delay=".25s">Leave A Message!</h3>
                <!-- <p class="wow fadeInUp" data-wow-delay=".15s">Get in touch to create some of the best memories</p> -->
            </div>
            <div class="col-md-12">
                <div class="cp-wrapper">
                    <!-- <h4>Leave A Message!</h4> -->
                    <form [formGroup]="contactForm" (ngSubmit)="sendEmail()">
                        <div class="row">
                            <div class="col-md-4 mt-3">
                                <div class="form-group">
                                    <label class="fields-align">Your Name*</label>
                                    <input type="user_account" [(ngModel)]="name" formControlName="name"
                                        class="form-control input-align" placeholder="Your Name Here">
                                </div>
                                <div *ngIf="isSubmitted && errorControl.name.errors?.required" class="field-error">
                                    Name is required
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="form-group">
                                    <label class="fields-align">Email Address*</label>
                                    <input type="email" class="form-control input-align" formControlName="email"
                                        [(ngModel)]="email" placeholder="Email Address Here">
                                </div>
                                <div *ngIf="isSubmitted && errorControl.email.errors?.required" class="field-error">
                                    Email is required
                                </div>
                            </div>

                            <div class="col-md-4  mt-3">
                                <div class="form-group">
                                    <label class="fields-align">Telephone Number*</label>
                                    <input type="text" formControlName="phone" class="form-control input-align"
                                        [(ngModel)]="phone" placeholder="Phone Number">
                                </div>
                                <div *ngIf="isSubmitted && errorControl.subject.errors?.required" class="field-error">
                                    Telephone Number is required
                                </div>
                            </div>
                            <div class="col-md-6 mt-3">
                                <div class="form-group">
                                    <label class="fields-align">Subject Line*</label>
                                    <textarea type="text" class="form-control input-align" formControlName="subject"
                                        [(ngModel)]="subject" placeholder="Subject"> </textarea>
                                </div>
                                <div *ngIf="isSubmitted && errorControl.subject.errors?.required" class="field-error">
                                    Subject is required
                                </div>
                            </div>
                            <div class="col-md-6  mt-3">
                                <div class="form-group">
                                    <label class="fields-align">Your Message*</label>
                                    <textarea class="form-control input-align" formControlName="message"
                                        [(ngModel)]="message" placeholder="Write Your Message"></textarea>
                                </div>
                                <div *ngIf="isSubmitted && errorControl.message.errors?.required" class="field-error">
                                    Message is required
                                </div>
                            </div>
                            <div class="col-md-12 text-center  mt-5 mb-5">
                                <button type="submit" id="submit" name="submit" class="cf-btn">Send
                                    Now</button>
                                <div class="cf-msg">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="success-msg">{{successMessage}}</div>
        </div>
    </div>
</div>
<div class="text-center core-service-area insta-section">
    <a href="https://www.instagram.com/pramaanphotography/" class="insta-btn insta-icon">
        <img src="assets/assets/img/icon/instagram.png" alt>
        <span class="insta-feed">INSTAGRAM</span>
        <p class="insta-highlight">Highlights</p>
    </a>
</div>
<div class="wrapper core-service-area">
    <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let slide of apiData">
            <ng-template class="slide d-flex align-items-center" carouselSlide [id]="slide.id">
                <video *ngIf="slide.media_type === 'VIDEO'" width="285" height="288" [src]="slide.media_url" controls
                    controlsList="nodownload"></video>
                <img *ngIf="slide.media_type === 'IMAGE' || slide.media_type === 'CAROUSEL_ALBUM'"
                    [src]="slide.media_url" [alt]="slide.id">
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>
<app-whatsapp></app-whatsapp>
<div class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="footer-copyr-logo">
                    <img src="assets/logo/p-logo-white.png" alt width="100px">
                    <p>© 2023 All rights reserved by Pramaangroup Events</p>
                </div>
                <div class="footer-social">
                    <ul>
                        <li><a [href]="instagramLink" (click)="openInstagramLink($event)"><i class="fa fa-instagram"
                                    aria-hidden="true"></i></a></li>
                        <li><a [href]="facebookLink" (click)="openFacebookLink($event)"><i class="fa fa-facebook"
                                    aria-hidden="true"></i></a></li>
                        <li><a [href]="linkedinLink" (click)="openLinkedinLink($event)"><i class="fa fa-linkedin"
                                    aria-hidden="true"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-4">
                <div class="footer-copyr-logo">
                    <p>Quick Links</p>
                </div>
                <div class="footer-widget">
                    <!-- /.footer-widget__title -->
                    <ul class="footer-widget__links list-unstyled">
                        <li class="mega-menu"><a (click)="scrollFunction('home')" class="quick-links">Home</a>
                        </li>
                        <li class="mega-menu"><a (click)="scrollFunction('about')" class="quick-links">About
                                Us</a>
                        </li>
                        <li class="mega-menu">
                            <a (click)="scrollFunction('services')" class="quick-links">Services</a>
                        </li>
                        <li class="mega-menu">
                            <a (click)="scrollFunction('gallery')" class="quick-links">Gallery</a>

                        </li>
                        <li><a (click)="scrollFunction('contacts')" class="quick-links">Contact
                                Us</a>
                        </li>
                    </ul>
                    <!-- /.footer-widget__links -->
                </div>
            </div>
            <div class="col-md-4">
                <div class="footer-copyr-logo">
                    <!-- <img src="assets/img/logo-1.png" alt> -->
                    <p>Contact Info</p>
                </div>
                <div class="footer-widget">
                    <p>Telangana : Plot No : 304K, Road Num : 78, Jubilee Hills, Hyderabad - 500096</p>
                    <p>Andhra Pradesh : #40-5-16, DV Manor Road, Above Mahendra Jewellery Opp. F3 Manor Food
                        Plaza, Tikkle Rd, Vijayawada, Andhra Pradesh 520010</p>

                </div>
            </div>
        </div>
    </div>
</div>