<header class="header-area fotolia-header header_1">
    <div class="topbar">
        <div class="container">
            <div class="row">
                <div class="col-md-7">
                    <div class="tb-contact-info">
                        <div class="phone-mail">
                            <span><i class="fa fa-phone" aria-hidden="true"></i><a style="color: white;"
                                    href="tel:9393769999">(+91)
                                    9393769999</a></span>
                            <span><i class="fa fa-envelope" aria-hidden="true"></i><a style="color: white;"
                                    href="mailto:events@pramaangroup.com" class="__cf_email__"
                                    data-cfemail="6703080a060e0927060303150214144904080a">events@pramaangroup.com</a></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="tb-contact-info">
                        <div class="location">
                            <span style="color: white;"><i class="fa fa-map-marker" aria-hidden="true"></i>Telangana & Andhra Pradesh</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="voidmega-header">
        <div class="container">
            <div class="bgvm">
                <div class="row">
                    <div class="col-12 col-xl-10">
                        <div class="vmm-header header-transparent-on vmm-mega-menu mega-menu-fullwidth header-align">
                            <div class="container">

                                <div class="vmm-header-container">

                                    <div class="logo" style="padding: 0px 10px 7px 0px;"
                                        data-mobile-logo="assets/logo/pramaan-logo.png" data-sticky-logo="assets/logo/pramaan-logo.png">
                                        <a routerLink=""><img src="assets/logo/pramaan-logo.png" style="width: 70px;"
                                                alt="logo" /></a>
                                    </div>

                                    <div class="burger-menu">
                                        <div class="line-menu line-half first-line">
                                        </div>
                                        <div class="line-menu"></div>
                                        <div class="line-menu line-half last-line">
                                        </div>
                                    </div>

                                    <nav class="vmm-menu menu-caret submenu-scale">
                                        <ul>
                                            <li class="mega-menu"><a href="#home">Home</a>
                                            </li>
                                            <li class="mega-menu"><a href="#about">About
                                                    Us</a>
                                            </li>
                                            <li class="mega-menu">
                                                <a href="#services">Services</a>
                                            </li>
                                            <li class="mega-menu">
                                                <a href="#gallery">Gallery</a>

                                            </li>
                                            <li><a href="#contacts">Contact
                                                    Us</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-none col-xl-2 d-xl-block">
                        <div class="search-menu-btn">
                            <div class="searchV1-btn">
                                <div class="soc-btn">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<app-preloader *ngIf="loading  && !errorMessage"></app-preloader>

<section class="hero-area hero_V1" #myElement style="background-image: url(../../../assets/uploads/about/pastevent-slide-1.jpg);">
    <div class="container">
        <div class="hero-content">
            <div class="row">
                <div class="col-md-12">
                    <div class="hero-text">
                        <h2 class="fadeIn" data-wow-delay=".5s"><span>Past Events</span>
                        </h2>
                        <p class="fadeIn" data-wow-delay=".99s">“A glimpse of some of the wonderful memories created”
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div *ngIf="!loading && !errorMessage">
    <section class="portfolio-area bgDark section-padding">
        <div class="container">
            <div class="">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6" *ngFor="let item of eventData; index as i">
                        <div class="offer-style-card" (click)="toggleCard(item)" style="cursor: pointer;">
                            <div class="offer-image">
                                <img src="{{ s3UrlLink + '/'+item.event_image_path}}"
                                    style="height: 280px; width: 100%;" />
                            </div>
                            <div class="offer-content">
                                <h3 style="color: black;">{{item.event_name}}</h3>
                                <h5 style="color: black;">{{item.eventdropDownData}}</h5>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<section class="portfolio-area bgDark section-padding" *ngIf="errorMessage">
    <div class="container">
        <div class="">
            <div class="row justify-content-center">
                <div class="col-md-12" style="display:contents">
                    <img src="../../../assets/images/error-img.png">
                </div>
            </div>
        </div>
    </div>
</section>
<div class="text-center core-service-area" style="padding: 10px;padding-top: 59px;">
    <a href="https://www.instagram.com/pramaanphotography/" class="insta-btn" style="font-size: 36px;font-weight: 500;">
        <img src="assets/assets/img/icon/instagram.png" alt>
        <span style="margin-left: 10px;color: #43435b;">INSTAGRAM</span>
        <p style="color: #43435b; font-size:18px;font-weight: 600">Highlights</p>
    </a>
</div>
<!-- <div class="row">
    <div class="col-md-12"> -->

<div class="wrapper">
    <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let slide of apiData">
            <ng-template class="slide" carouselSlide [id]="slide.id">
                <video *ngIf="slide.media_type === 'VIDEO'" width="285" height="288" [src]="slide.media_url" controls controlsList="nodownload"></video>
                <img *ngIf="slide.media_type === 'IMAGE' || slide.media_type === 'CAROUSEL_ALBUM'" [src]="slide.media_url" [alt]="slide.id">
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>
<app-whatsapp></app-whatsapp>
<div class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="footer-copyr-logo">
                    <img src="assets/logo/p-logo-white.png" alt style="width:100px">
                    <p>© 2023 All rights reserved by Pramaangroup Events</p>
                </div>
                <div class="footer-social">
                    <ul>
                        <li><a [href]="instagramLink" (click)="openInstagramLink($event)"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                        <li><a [href]="facebookLink" (click)="openFacebookLink($event)"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                        <li><a [href]="linkedinLink" (click)="openLinkedinLink($event)"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-4">
                <div class="footer-copyr-logo">
                    <p>Quick Links</p>
                </div>
                <div class="footer-widget">
                    <!-- /.footer-widget__title -->
                    <ul class="footer-widget__links list-unstyled">
                        <li class="mega-menu"><a style="color: white;" href="#home">Home</a>
                        </li>
                        <li class="mega-menu"><a style="color: white;" href="#about">About
                                Us</a>
                        </li>
                        <li class="mega-menu">
                            <a style="color: white;" href="#services">Services</a>
                        </li>
                        <li class="mega-menu">
                            <a style="color: white;" href="#gallery">Gallery</a>

                        </li>
                        <li class="mega-menu"><a style="color: white;" href="#contacts">Contact Us</a>
                        </li>
                    </ul>
                    <!-- /.footer-widget__links -->
                </div>
            </div>
            <div class="col-md-4">
                <div class="footer-copyr-logo">
                    <!-- <img src="assets/img/logo-1.png" alt> -->
                    <p>Contact Info</p>
                </div>
                <div class="footer-widget">
                    <p>Telangana : Plot No : 304K, Road Num : 78, Jubilee Hills, Hyderabad - 500096</p>
                    <p>Andhra Pradesh : #40-5-16, DV Manor Road, Above Mahendra Jewellery Opp. F3 Manor Food
                        Plaza, Tikkle Rd, Vijayawada, Andhra Pradesh 520010</p>
                   
                </div>
            </div>
        </div>
    </div>
</div>