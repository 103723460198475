<header class="header-area fotolia-header header_1">
    <div class="topbar">
        <div class="container">
            <div class="row">
                <div class="col-md-7">
                    <div class="tb-contact-info">
                        <div class="phone-mail">
                            <span><i class="fa fa-phone" aria-hidden="true"></i><a style="color: white;"
                                    href="tel:9393769999">(+91)
                                    9393769999</a></span>
                            <span><i class="fa fa-envelope" aria-hidden="true"></i><a style="color: white;"
                                    href="mailto:events@pramaangroup.com" class="__cf_email__"
                                    data-cfemail="6703080a060e0927060303150214144904080a">events@pramaangroup.com</a></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="tb-contact-info">
                        <div class="location">
                            <span style="color: white;"><i class="fa fa-map-marker" aria-hidden="true"></i>Telangana & Andhra Pradesh</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="voidmega-header">
        <div class="container">
            <div class="bgvm">
                <div class="row">
                    <div class="col-12 col-xl-10">
                        <div class="vmm-header header-transparent-on vmm-mega-menu mega-menu-fullwidth header-align">
                            <div class="container">

                                <div class="vmm-header-container">

                                    <div class="logo logo-alignment"
                                        data-mobile-logo="assets/logo/pramaan-logo.png" data-sticky-logo="assets/logo/pramaan-logo.png">
                                        <a routerLink=""><img src="assets/logo/pramaan-logo.png" class="logo-width"
                                                alt="logo" /></a>
                                    </div>

                                    <div class="burger-menu">
                                        <div class="line-menu line-half first-line">
                                        </div>
                                        <div class="line-menu"></div>
                                        <div class="line-menu line-half last-line">
                                        </div>
                                    </div>

                                    <nav class="vmm-menu menu-caret submenu-scale">
                                        <ul>
                                            <li class="mega-menu"><a href="#home">Home</a>
                                            </li>
                                            <li class="mega-menu"><a href="#about">About
                                                    Us</a>
                                            </li>
                                            <li class="mega-menu">
                                                <a href="#services">Services</a>
                                            </li>
                                            <li class="mega-menu">
                                                <a href="#gallery">Gallery</a>

                                            </li>
                                            <li><a href="#contacts">Contact
                                                    Us</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-none col-xl-2 d-xl-block">
                        <div class="search-menu-btn">
                            <div class="searchV1-btn">
                                <div class="soc-btn">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<section class="hero-area hero_V1 about-bgimg">
    <div class="container">
        <div class="hero-content">
            <div class="row">
                <div class="col-md-12">
                    <div class="hero-text">
                        <h2 class="fadeIn" data-wow-delay=".5s"><span>About Us</span>
                        </h2>
                        <p class="fadeIn" data-wow-delay=".99s">“Making your special day worth remembering down memory
                            lane”</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="about-page-area bgDark section-padding">
    <div class="container">
        <div class="row">
            <div class="col-md-6 d-flex align-items-center">
                <div class="about-img">
                    <img id="aboutimg1responsive" src="../../../assets/uploads/about/about1-mobile.jpg" width="100%" alt>
                    <img id="aboutimg2responsive" src="../../../assets/uploads/about/about1-mobile-1.jpg" width="100%" alt="">
                </div>
            </div>
            <div class="col-md-6">
                <div class="about-text about-p1-top">
                    <div class="section-text">
                        <div class="section-titleV1">
                            <h3 class="wow fadeInUp why-events" data-wow-delay=".25s">
                                Why Choose Pramaangroup Events</h3>
                        </div>
                        <p class="wow fadeInUp about-content" data-wow-delay=".35s">Events are an opportunity to
                            connect and
                            celebrate. An event is measured by the number of smiles it spreads. We celebrate events as
                            they create lasting memories that can be cherished for a lifetime.Memories can linger on if
                            an event is planned in a right way and with the right people.
                        </p>
                        <ul>
                            <li class="wow fadeInUp" data-wow-delay=".45s">"At Pramaan group, we meticulously handle
                                each event that we undertake. We understand your requirements and budget and try to
                                deliver superior service, without compromising on the quality."
                                Our team of experienced
                                and skilled professionals put their heart and soul into making your special event worth
                                remembering a lifetime."
                            </li>
                        </ul>
                        <p class="wow fadeInUp about-content" data-wow-delay=".75">Each project that we
                            undertake is dealt with utmost
                            care and precision. We analyse your requirements well and then suggest you designs solely
                            based on your requirements.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="apa-1-2nd-section-left">
                    <p class="about-content">Events like moment,
                        an instant. You need a half
                        second to get the photo.
                        So good to capture people
                        when they are themselves.
                        I became passionate.”</p>
                </div>
            </div>
            <div class="col-md-8">
                <div class="apa-1-2nd-section-right">
                    <p class="about-content">Pramaan Events creates outstanding experiences for every occasion by
                        bringing your vision to life. The flawless and stress-free execution of events that create a
                        lasting impression on you and your guests is a top priority for our devoted team of event
                        planners.</p>
                    <p class="about-content"> We handle every detail, ensuring your event is nothing short of amazing,
                        whether it is
                        a small gathering or a large-scale celebration.</p>
                    <p class="about-content">All our concepts and designs are unique. We understand how precious your
                        special occasion is to you and we leave no stone unturned to make it grand. Once you come to us
                        with your requirement, your special day becomes ours!</p>
                        <p class="about-content"> We handle every detail, ensuring your event is nothing short of amazing,
                            whether it is
                            a small gathering or a large-scale celebration.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-7">
                <div class="section-text st-white apa-1-3rd-section-left">
                    <div class="section-titleV1">
                        <h3 class="about-content1">Events is a way of
                            feeling, of touching, of
                            loving. What you have!</h3>
                    </div>
                    <p class="about-content">We believe that every event is unique, and we pride ourselves on tailoring
                        our services to meet
                        your specific needs and preferences. Our event planners work closely with you to understand your
                        vision and execute it flawlessly. We collaborate with top professionals who share our commitment
                        to excellence.</p>
                    <p class="about-content">Let Pramaan events take the stress out of event planning, allowing you to
                        fully enjoy and savor every moment of your special occasion. Contact us today, and together,
                        we'll create an event that will be etched in your heart forever.</p>
                    <a href="#contacts" class="btn-style-1">Contact Us</a>
                </div>
            </div>
            <div class="col-md-5 d-flex align-items-center">
                <div class="about-p-img-2 apa-1-3rd-section-right">
                    <img id="img1" src="../../../assets/uploads/about/about3-1.jpg" width="100%" alt>
                    <img id="img2" src="../../../assets/uploads/about/about3-potrait-1.jpg" width="100%" alt="">
                </div>
            </div>
        </div>
    </div>
</section>


<div class="text-center core-service-area insta-section">
    <a href="https://www.instagram.com/pramaanphotography/" class="insta-btn insta-icon">
        <img src="assets/assets/img/icon/instagram.png" alt>
        <span class="insta-feed">INSTAGRAM</span>
        <p class="insta-highlight">Highlights</p>
    </a>
</div>

<div class="wrapper">
    <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let slide of apiData">
            <ng-template class="slide" carouselSlide [id]="slide.id">
                <video *ngIf="slide.media_type === 'VIDEO'" width="285" height="288" [src]="slide.media_url" controls controlsList="nodownload"></video>
                <img *ngIf="slide.media_type === 'IMAGE' || slide.media_type === 'CAROUSEL_ALBUM'" [src]="slide.media_url" [alt]="slide.id">
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>
<app-whatsapp></app-whatsapp>
<div class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="footer-copyr-logo">
                    <img src="assets/logo/p-logo-white.png" alt width="100px">
                    <p>© 2023 All rights reserved by Pramaangroup Events</p>
                </div>
                <div class="footer-social">
                    <ul>
                        <li><a [href]="instagramLink" (click)="openInstagramLink($event)"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                        <li><a [href]="facebookLink" (click)="openFacebookLink($event)"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                        <li><a [href]="linkedinLink" (click)="openLinkedinLink($event)"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-4">
                <div class="footer-copyr-logo">
                    <p>Quick Links</p>
                </div>
                <div class="footer-widget">
                    <!-- /.footer-widget__title -->
                    <ul class="footer-widget__links list-unstyled">
                        <li class="mega-menu"><a class="quick-links" href="#home">Home</a>
                        </li>
                        <li class="mega-menu"><a class="quick-links" href="#about">About
                                Us</a>
                        </li>
                        <li class="mega-menu">
                            <a class="quick-links" href="#services">Services</a>
                        </li>
                        <li class="mega-menu">
                            <a class="quick-links" href="#gallery">Gallery</a>

                        </li>
                        <li class="mega-menu"><a class="quick-links" href="#contacts">Contact Us</a>
                        </li>
                    </ul>
                    <!-- /.footer-widget__links -->
                </div>
            </div>
            <div class="col-md-4">
                <div class="footer-copyr-logo">
                    <!-- <img src="assets/img/logo-1.png" alt> -->
                    <p>Contact Info</p>
                </div>
                <div class="footer-widget">
                    <p>Telangana : Plot No : 304K, Road Num : 78, Jubilee Hills, Hyderabad - 500096</p>
                    <p>Andhra Pradesh : #40-5-16, DV Manor Road, Above Mahendra Jewellery Opp. F3 Manor Food
                        Plaza, Tikkle Rd, Vijayawada, Andhra Pradesh 520010</p>
                  
                </div>
            </div>
        </div>
    </div>
</div>