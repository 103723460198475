<header class="header-area fotolia-header header_1">
    <div class="topbar">
        <div class="container">
            <div class="row">
                <div class="col-md-7">
                    <div class="tb-contact-info">
                        <div class="phone-mail">
                            <span><i class="fa fa-phone" aria-hidden="true"></i><a style="color: white;"
                                    href="tel:9393769999">(+91)
                                    9393769999</a></span>
                            <span><i class="fa fa-envelope" aria-hidden="true"></i><a style="color: white;"
                                    href="mailto:pramaanphotography@gmail.com" class="__cf_email__"
                                    data-cfemail="6703080a060e0927060303150214144904080a">pramaanphotography@gmail.com</a></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="tb-contact-info">
                        <div class="location">
                            <span style="color: white;"><i class="fa fa-map-marker" aria-hidden="true"></i>Hyderabad and
                                Vijayawada</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="voidmega-header">
        <div class="container">
            <div class="bgvm">
                <div class="row">
                    <div class="col-12 col-xl-10">
                        <div class="vmm-header header-transparent-on vmm-mega-menu mega-menu-fullwidth header-align">
                            <div class="container">

                                <div class="vmm-header-container">

                                    <div class="logo" style="padding: 0px 10px 7px 0px;"
                                        data-mobile-logo="assets/logo/pramaan-logo.png" data-sticky-logo="assets/logo/pramaan-logo.png">
                                        <a routerLink=""><img src="assets/logo/pramaan-logo.png" style="width: 70px;"
                                                alt="logo" /></a>
                                    </div>

                                    <div class="burger-menu">
                                        <div class="line-menu line-half first-line">
                                        </div>
                                        <div class="line-menu"></div>
                                        <div class="line-menu line-half last-line">
                                        </div>
                                    </div>

                                    <nav class="vmm-menu menu-caret submenu-scale">
                                        <ul>
                                            <li class="mega-menu"><a href="#home">Home</a>
                                            </li>
                                            <li class="mega-menu"><a href="#about">About
                                                    Us</a>
                                            </li>
                                            <li class="mega-menu">
                                                <a href="#services">Services</a>
                                            </li>
                                            <li class="mega-menu">
                                                <a href="#gallery">Gallery</a>

                                            </li>
                                            <li><a href="#contact">Contact
                                                    Us</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-none col-xl-2 d-xl-block">
                        <div class="search-menu-btn">
                            <div class="searchV1-btn">
                                <div class="soc-btn">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<section class="hero-area hero_V1" style="background-image: url(../../../assets/img/slides/contact.jpeg);">
    <div class="container">
        <div class="hero-content">
            <div class="row">
                <div class="col-md-12">
                    <div class="hero-text">
                        <h2 class="fadeIn" data-wow-delay=".5s"><span>Contact Us</span>
                        </h2>
                        <p class="fadeIn" data-wow-delay=".99s">“Plans are nothing. Planning is everything.” </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="contact-page-wrapper section-padding">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="contact-info">
                    <h4>Contact Info:</h4>
                    <div class="ci-single">
                        <div class="cis-icon">
                            <i class="fa fa-phone" aria-hidden="true"></i>
                        </div>
                        <div class="cis-text">
                            <p><a href="tel:(+91) 9393769999">(+91) 9393769999</a></p>
                            <!-- <p>+22 8484 5959 25</p> -->
                        </div>
                    </div>
                    <div class="ci-single">
                        <div class="cis-icon">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                        </div>
                        <div class="cis-text">
                            <p><a href="mailto:pramaanphotography@gmail.com"
                                    class="__cf_email__">pramaanphotography@gmail.com</a>
                            </p>

                        </div>
                    </div>
                    <div class="ci-single">
                        <div class="cis-icon">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                        </div>
                        <div class="cis-text">
                            <p>#40-5-16, DV Manor Road, Above Mahendra Jewellery Opp. F3 Manor Food Plaza, Tikkle Rd,
                                Vijayawada, Andhra Pradesh 520010</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="cp-wrapper">
                    <h4>Leave A Message!</h4>
                    <form class="cf" method="post" action="assets/mail.php">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Your Name*</label>
                                    <input type="text" id="name" name="name" class="form-control"
                                        placeholder="Your Name Here">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Email Address*</label>
                                    <input type="email" id="email" name="email" class="form-control"
                                        placeholder="Email Address Here">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Subject Line*</label>
                                    <input type="text" class="form-control" id="subject" name="subject"
                                        placeholder="Subject">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Telephone Number*</label>
                                    <input type="text" id="phone" name="phone" class="form-control"
                                        placeholder="Phone Number">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Your Message*</label>
                                    <textarea class="form-control" id="message" name="message"
                                        placeholder="Write Your Message"></textarea>
                                </div>
                                <button type="submit" id="submit" name="submit" class="cf-btn">Send Now</button>
                                <div class="cf-msg" style="display: none;">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="instagram-btn">
    <a href="https://www.instagram.com/pramaanphotography/" class="insta-btn">
        <img src="assets/assets/img/icon/instagram.png" alt>
        <span style="margin-left: 10px;">Instagram_Feed</span>
    </a>
</div>
<!-- <div class="row">
    <div class="col-md-12"> -->

        <div class="wrapper">
            <owl-carousel-o [options]="customOptions">
                <ng-container *ngFor="let slide of apiData">
                    <ng-template class="slide" carouselSlide [id]="slide.id">
                        <img [src]="slide.media_url" [alt]="slide.id" [title]="slide.id">
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    <!-- </div>
</div> -->
<div class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="footer-copyr-logo">
                    <img src="assets/logo/p-logo-white.png" alt style="width:100px">
                    <p>© 2023 All rights reserved by Pramaangroup Events</p>
                </div>
                <div class="footer-social">
                    <ul>
                        <!-- <li><a href=""><i class="fa fa-twitter" aria-hidden="true"></i></a></li> -->
                        <li><a href="https://www.instagram.com/pramaanevents/"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                        <li><a href="https://www.facebook.com/pramaanevents"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                        <!-- <li><a href="https://www.pinterest.com/voidthemes/"><i
                                                                    class="fa fa-pinterest-p"
                                                                    aria-hidden="true"></i></a></li> -->
                        <!-- <li><a href=""><i class="fa fa-linkedin" aria-hidden="true"></i></a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-md-4">
                <div class="footer-copyr-logo">
                    <p>Quick Links</p>
                </div>
                <div class="footer-widget">
                    <!-- /.footer-widget__title -->
                    <ul class="footer-widget__links list-unstyled">
                        <li class="mega-menu"><a href="#home">Home</a>
                        </li>
                        <li class="mega-menu"><a href="#about">About
                                Us</a>
                        </li>
                        <li class="mega-menu">
                            <a href="#services">Services</a>
                        </li>
                        <li class="mega-menu">
                            <a href="#gallery">Gallery</a>

                        </li>
                        <li class="mega-menu"><a style="color: white;" href="#contact">Contact Us</a>
                        </li>
                    </ul>
                    <!-- /.footer-widget__links -->
                </div>
            </div>
            <div class="col-md-4">
                <div class="footer-copyr-logo">
                    <!-- <img src="assets/img/logo-1.png" alt> -->
                    <p>Contact Info</p>
                </div>
                <div class="footer-widget">
                    <p>#40-5-16, DV Manor Road, Above Mahendra Jewellery Opp. F3 Manor Food
                        Plaza, Tikkle Rd, Vijayawada, Andhra Pradesh 520010</p>
                </div>
            </div>
        </div>
    </div>
</div>