import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private baseUrl = environment.apiUrl
  url = "/events"
  constructor(private httpClient: HttpClient) { }
  getHeaders() {
    return new HttpHeaders().set('istoken', 'false');
  }
  public getPastEvents(): Observable<Object> {
    // geteventList() {
      return this.httpClient.get(this.baseUrl + this.url, { headers: this.getHeaders() })
    }
  //   const headers = new HttpHeaders().set("istoken",
  //     'false');
  //   return this.httpClient
  //     .get(this.baseUrl + '/api/v1/events?pastevents=true', { headers: headers })
  //     .pipe(
  //   );
  // }
  // getEventById(id: any) {
  //   return new HttpHeaders().set('istoken', 'false');
  // }
  public getEventById(id): Observable<Object> {
    return this.httpClient.get(this.baseUrl + this.url +'/' + id, { headers: this.getHeaders() })
    // const headers = new HttpHeaders().set("istoken",
    //   'false');
    // return this.httpClient
    //   .get(this.baseUrl + '/api/v1/events/' + id, { headers: headers })
    //   .pipe(
    // );
  }
  public getGoogleDriveImages(id: any): any {
    const headers = new HttpHeaders().set("istoken",
      'false');
    return this.httpClient
      .get(environment.apiUrl+'/readDriveFiles?type=files&folderId=' + id, { headers: headers })
      .pipe(
    );
  }
  public getUpcomingEvents(): Observable<Object> {
    const headers = new HttpHeaders().set("istoken",
      'false');
      return this.httpClient.get(this.baseUrl + this.url, { headers: this.getHeaders() })
  }
  sendMail(userObj: any): Observable<Object> {
    const headers = new HttpHeaders().set("istoken",
      'false');
    return this.httpClient
      .post(this.baseUrl + '/api/v1/users?type=email', userObj, { headers: headers })
      .pipe(
    );
  }
  public getDriveImagesGallery(): Observable<Object> {
    const headers = new HttpHeaders().set("istoken",
      'false');

      return this.httpClient.get(this.baseUrl +'/eventConfig?configoption_name=Events Config',{headers:headers})
    // return this.httpClient
    //   // .get('assets/jsons/event.json', { headers: headers })
    //   .get(this.baseUrl + '/api/v1/configoptions?configoption_name=Events Config', { headers: headers })
    //   .pipe(
    // );
  }
  public getStaticDriveImagesGallery(): Observable<Object> {
    const headers = new HttpHeaders().set("istoken",
      'false');
      return this.httpClient.get(this.baseUrl + '/appConfig?configoption_name=Config Options',{headers:headers})
    // return this.httpClient
    //   .get(this.baseUrl + '/api/v1/configoptions?configoption_name=Config Options', { headers: headers })
    //   .pipe(
    // );
  }
  geteventtypeDropDown() {
const headers = new HttpHeaders()
return this.httpClient.get(this.baseUrl + '/eventType?export=true', { headers: headers })
 }
 getInstagramFeed() {
  return this.httpClient.get(this.baseUrl + '/readInstaEvents')
 }
}
