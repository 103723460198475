import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
interface Tab {
  label: string;
  images: string[]; // Array of image URLs to display in each tab's content
}
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  apiData: any;
  galleryData: any = [];
  driveGalleryImages: any = [];
  loading: boolean = true;
  galleryImagesData: any;
  mainHomeGallery: any = [];
  instaToken: any;
  customOptions: OwlOptions = {
    loop: true,
    margin: 20,
    dots: false,
    autoplay: true,
    slideTransition: 'linear',
    autoplaySpeed: 3000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 5
      }
    }
  }
  // categoriesArray = [
  //   {
  //     id:'main_gallery',
  //     name:'All'
  //   },
  //   {
  //     id:'halfsaree_dhoti_event',
  //     name: 'Halfsaree & Dhoti'
  //   },
  //   {
  //     id:'first_birthday',
  //     name:'First Birthday'
  //   },
  //   {
  //     id: 'engagement_link',
  //     name:'Engagement'
  //   },
  //   {
  //     id: 'mehandi_link',
  //     name:'Mehandi'
  //   },
  //   {
  //     id: 'sangeeth_link',
  //     name:'Sangeeth'
  //   },
  //   {
  //     id: 'bride_making',
  //     name:'Bride Making'
  //   },
  //   {
  //     id: 'groom_making',
  //     name:'Groom Making'
  //   },
  //   {
  //     id: 'haldi_link',
  //     name:'Haldi'
  //   },
  //   {
  //     id: 'wedding_link',
  //     name:'Wedding'
  //   },
  //   {
  //     id: 'vratham_link',
  //     name:'Vratham'
  //   },
  //   {
  //     id: 'reception_link',
  //     name:'Reception'
  //   },
  //   {
  //     id: 'house_decoration',
  //     name:'House Decor'
  //   },
  // ]
  data: any;
  halfDhoti: any;
  birthday: any;
  engagement: any;
  mehandi: any;
  sangeeth: any;
  brideMaking: any;
  groomMaking: any;
  haldi: any;
  wedding: any;
  vratham: any;
  reception: any;
  houseDecor: any;
  configKeys: any;
  galleryObject = []
  mainGallery: any;
  galleryofImages: any;
  // googleAuthKey: any;
  tabsArray: any = [{ id: 'all', name: 'All' },
  { id: 'halfsaree_dhoti_event', name: 'Halfsaree & Dhoti' },
  { id: 'first_birthday', name: 'First Birthday' },
  { id: 'engagement_link', name: 'Engagement' },
  { id: 'mehandi_link', name: 'Mehandi' },
  { id: 'sangeeth_link', name: 'Sangeeth' },
  { id: 'bride_making', name: 'Bride Making' },
  { id: 'groom_making', name: 'Groom Making' },
  { id: 'haldi_link', name: 'Haldi' },
  { id: 'wedding_link', name: 'Wedding' },
  { id: 'vratham_link', name: 'Vratham' },
  { id: 'reception_link', name: 'Reception' },
  { id: 'house_decoration', name: 'House Decor' },
  ]
  selectedTabId: any;
  instagramLink: any;
  facebookLink: any;
  linkedinLink: any;
  constructor(private readonly http: HttpClient, private eventService: EventsService, private router: Router) { }
  ngOnInit(): void {
    this.getDriveImages()

  }
  fetch() {
    const api = `https://graph.instagram.com/me/media?fields=caption,id,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=${this.instaToken}`;
    const http$ = this.http.get(api);
    http$.subscribe(
      (res) =>
        this.apiData = res['data'],
    )
  }
  scrollFunction(e: any) {
    var scrollDiv = document.getElementById(e).offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
  }
  getDriveImages() {
    var dataObj = [];
    this.eventService.getDriveImagesGallery().subscribe((response: any) => {
      this.data = response
      this.instagramLink = this.data?.instagram_link
      this.facebookLink = this.data?.facebook_link
      this.linkedinLink = this.data.linkedin_link
      this.instaToken = this.data?.insta_access_event
      this.configKeys = Object.keys(response)
      for (let index = 0; index < this.configKeys?.length; index++) {
        dataObj.push({ link: this.data[this.configKeys[index]], name: this.configKeys[index] })
      }
      console.log(dataObj);
      this.galleryObject = dataObj;
      this.fetch()
      this.getDriveData();
      setTimeout(() => {
        this.loading = false;
      }, 100);

    }, error => {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    })
  }
  openInstagramLink(event: Event) {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open(this.instagramLink);
  }
  openFacebookLink(event: Event) {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open(this.facebookLink);
  }

  openLinkedinLink(event: Event) {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open(this.linkedinLink);
  }
  getDriveData() {
    if (this.galleryObject && this.galleryObject.length) {
      const obj = {
        drive_images: [],
        name: 'all',
        link: ''
      }
      this.galleryObject.unshift(obj)
      for (let index = 1; index < this.galleryObject.length; index++) {
        if (this.galleryObject[index]?.name != 'event_video_link' && this.galleryObject[index]?.name != 'past_events_video_link' && this.galleryObject[index]?.name != 'insta_access_event') {

          const linkArray = this.galleryObject[index].link.split('/')
          const id = linkArray[linkArray?.length - 1]

          this.eventService.getGoogleDriveImages(id).subscribe((response: any) => {
            this.galleryObject[index].drive_images = response[0]?.files
            if (this.galleryObject[index]?.name != 'event_video_link' && this.galleryObject[index]?.name != 'past_events_video_link' && this.galleryObject[index]?.name != 'insta_access_event') {
              this.galleryObject[0].drive_images = [...this.galleryObject[0]?.drive_images, ...response[0]?.files]
            }
            setTimeout(() => {
              this.loading = false;
            }, 100);
          }, error => {
            setTimeout(() => {
              this.loading = false;
            }, 100);
          })
        }
      }
    }
    setTimeout(() => {
      this.obGalleryItemClick('all')

    }, 2000);
  }

  obGalleryItemClick(divId) {
    for (let index = 0; index < this.tabsArray.length; index++) {
      if (divId == this.tabsArray[index]?.id) {
        this.selectedTabId = this.tabsArray[index]?.id
        this.tabsArray[index].Enabled = true;
      } else {
        this.tabsArray[index].Enabled = false;
      }
    }
    for (let index = 0; index < this.galleryObject.length; index++) {
      if (divId == this.galleryObject[index]?.name) {
        this.galleryObject[index].Enabled = true;
      } else {
        this.galleryObject[index].Enabled = false;
      }
    }
  }
  viewMore() {
    this.router.navigateByUrl('/all-images/' + this.selectedTabId)
  }

}
