import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from '../services/events.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-pastevent-details',
  templateUrl: './pastevent-details.component.html',
  styleUrls: ['./pastevent-details.component.scss']
})
export class PasteventDetailsComponent implements OnInit {
  recordId: any;
  eventData: any;
  apiData: any;
  limit: number = 10; // <==== Edit this number to limit API results
  customOptions: OwlOptions = {
    loop: true,
    margin: 20,
    dots: false,
    autoplay: true,
    slideTransition: 'linear',
    // autoplayTimeout: 0,
    autoplaySpeed: 3000,
    // autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 5
      }
    }
  }
  loading: boolean = true;
  coverimage: any;
  name: any;
  description: any;
  eventdate: any;
  eventimg: any;
  address: string;
  area: any;
  street: string;
  city: any;
  eventtype: any;
  mainTitle: any;
  videoLink: any;
  galleryData: any;
  instaToken: any;
  driveImages: any;
  s3UrlLink = environment.s3_URL
  instagramLink: any;
  linkedinLink: any;
  facebookLink: any;
  constructor(private route: ActivatedRoute, private eventsService: EventsService,
    private readonly http: HttpClient, private sanitizer: DomSanitizer) {
    this.route.params.subscribe(params => {
      this.recordId = params?.id;
    });
  }
  ngOnInit(): void {
    this.getDriveImages()
    this.eventsService.getEventById(this.recordId).subscribe((response: any) => {
      this.eventData = response
      this.coverimage = this.s3UrlLink + '/' + this.eventData?.cover_image_path
      this.name = this.eventData?.event_name
      this.mainTitle = this.eventData?.main_title
      this.description = this.eventData?.event_description
      this.eventdate = this.eventData?.event_date
      this.eventimg = this.s3UrlLink + '/' + this.eventData?.event_image_path
      this.eventtype = this.eventData?.event_type
      this.videoLink = this.eventData?.gallerydrive_link
      this.getAllGalleryImage(this.eventData?.gallerydrive_link)
    })
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }
  getAllGalleryImage(link) {
    const linkArray = link?.split('/')
    if (linkArray) {
      const id = linkArray[linkArray.length - 1]
      this.eventsService.getGoogleDriveImages(id).subscribe((response: any) => {
        this.driveImages = response[0]?.files
        setTimeout(() => {
          this.loading = false;
        }, 100);
      }, error => {
        setTimeout(() => {
          this.loading = false;
        }, 100);
      })
    }
  }
  fetch() {
    const api = `https://graph.instagram.com/me/media?fields=caption,id,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=${this.instaToken}`;
    const http$ = this.http.get(api);
    http$.subscribe(
      (res) =>
        this.apiData = res['data'],
    )
  }
  scrollFunction(e: any) {
    var scrollDiv = document.getElementById(e).offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
  }
  getVideoLink() {
    return this.sanitizer.bypassSecurityTrustUrl(this.videoLink);
  }
  getDriveImages() {
    this.eventsService.getDriveImagesGallery().subscribe((response: any) => {
      this.galleryData = response
      this.instagramLink = response?.instagram_link
      this.facebookLink = response?.facebook_link
      this.linkedinLink = response?.linkedin_link
      this.instaToken = this.galleryData?.insta_access_event
      this.fetch()
      setTimeout(() => {
        this.loading = false;
      }, 100);

    }, error => {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    })
  }
  openInstagramLink(event: Event) {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open(this.instagramLink);
  }
  openFacebookLink(event: Event) {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open(this.facebookLink);
  }

  openLinkedinLink(event: Event) {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open(this.linkedinLink);
  }
}
