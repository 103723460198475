import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { EventsService } from 'src/app/services/events.service';
import { environment } from 'src/environments/environment';
declare var jarallax: any;


@Component({
  selector: 'app-upcoming',
  templateUrl: './upcoming.component.html',
  styleUrls: ['./upcoming.component.scss'],
  providers: [DatePipe]
})
export class UpcomingComponent implements OnInit, AfterViewInit {
  @ViewChild('myElement', { static: false }) myElementRef!: ElementRef

  eventData: any;
  upcomingevents: any;
  currentDate = new Date();
  myDate: string;
  upcomingEventsDate: string;
  filtered_list = []
  apiData: any;
  limit: number = 10; // <==== Edit this number to limit API results
  customOptions: OwlOptions = {
    loop: true,
    margin: 20,
    dots: false,
    autoplay: true,
    slideTransition: 'linear',
    // autoplayTimeout: 0,
    autoplaySpeed: 3000,
    // autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 5
      }
    }
  }
  loading: boolean = true;
  galleryData: any;
  instaToken: any;
  mainVideogallery = 'mp4:assets/images/eventvideo.mp4';
  mainHomeGalleryVideo: any = []
  mainVideo: any;
  driveGalleryImages: any;
  googleAuthKey: any;
  s3UrlLink = environment.s3_URL
  errorMessage = false;
  instagramLink: any;
  facebookLink: any;
  linkedinLink: any;
  pasteventsDropDownList: any;
  pasteventsTypeObj: any;
  eventdate: string;
  constructor(private eventservice: EventsService, private datePipe: DatePipe, private readonly http: HttpClient) { }

  ngOnInit(): void {
    this.getDriveImages()
    this.getpasteventDropDown()
    this.myDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.eventservice.getUpcomingEvents().subscribe((response: any) => {
      this.eventData = response.data
      for (let index = 0; index < this.eventData.length; index++) {
        this.pasteventsTypeObj = this.pasteventsDropDownList.find((element: any) => {
          return element.id == this.eventData[index].event_type
        });
        this.eventData[index].eventdropDownData = this.pasteventsTypeObj.name
        this.eventdate = this.datePipe.transform(response.data[index].event_date, 'yyyy-MM-dd');
      }
      if (response.totalCount === 0) {
        this.errorMessage = true;
      } else {
        this.errorMessage = false;
      }
      this.eventData = response.data.filter((item) => {
        const itemDate = new Date(item.event_date);
        const filterDateObj = new Date();
        return itemDate > filterDateObj;
      });

      setTimeout(() => {
        this.loading = false;
      }, 100);
    }, error => {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    })
  }
  fetch() {
    const api = `https://graph.instagram.com/me/media?fields=caption,id,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=${this.instaToken}`;
    const http$ = this.http.get(api);
    http$.subscribe(
      (res) =>
        this.apiData = res['data'],
    )
  }
  scrollFunction(e: any) {
    var scrollDiv = document.getElementById(e).offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
  }
  getDriveImages() {
    this.eventservice.getDriveImagesGallery().subscribe((response: any) => {
      this.galleryData = response
      // this.galleryData = response?.data[0]?.configoption_json
      this.instagramLink = response?.instagram_link
      this.facebookLink = response?.facebook_link
      this.linkedinLink = response?.linkedin_link
      this.mainVideo = this.galleryData?.event_video_link
      this.instaToken = this.galleryData?.insta_access_event
      this.extractFileId(this.mainVideo)
      this.fetch()
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }, error => {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    })
  }
  openInstagramLink(event: Event) {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open(this.instagramLink);
  }
  openFacebookLink(event: Event) {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open(this.facebookLink);
  }

  openLinkedinLink(event: Event) {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open(this.linkedinLink);
  }
  extractFileId(url: string): string {
    const pattern = /\/file\/d\/([a-zA-Z0-9_-]+)\//;
    const match = url.match(pattern);

    if (match && match[1]) {
      this.mainVideogallery = 'mp4:https://drive.google.com/uc?id=' + match[1];
      // this.initJarallax();
    }

    return '';
  }
  getpasteventDropDown() {
    this.eventservice.geteventtypeDropDown().subscribe((response: any) => {
      this.pasteventsDropDownList = response

    })
  }

  ngAfterViewInit() {

  }
}

