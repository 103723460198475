import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  apiData: any;
  limit: number = 10; // <==== Edit this number to limit API results
  customOptions: OwlOptions = {
    loop: true,
    margin: 20,
    dots: false,
    autoplay: true,
    slideTransition: 'linear',
    // autoplayTimeout: 0,
    autoplaySpeed: 3000,
    // autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 5
      }
    }
  }
  galleryData: any;
  instaToken: any;
  loading = true;
  instagramLink: any;
  facebookLink: any;
  linkedinLink: any;
  constructor(private readonly http: HttpClient, private eventService: EventsService) { }
  ngOnInit() {
    this.getDriveImages()
  }
  fetch() {
    const api = `https://graph.instagram.com/me/media?fields=caption,id,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=${this.instaToken}`;
    const http$ = this.http.get(api);

    http$.subscribe(
      (res) =>
        this.apiData = res['data'],
    )
  }
  scrollFunction(e: any) {
    var scrollDiv = document.getElementById(e).offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
  }
  getDriveImages() {
    this.eventService.getDriveImagesGallery().subscribe((response: any) => {
      this.galleryData = response
      this.instagramLink = response?.instagram_link
      this.facebookLink = response?.facebook_link
      this.linkedinLink = response?.linkedin_link
      this.instaToken = this.galleryData?.insta_access_event
      this.fetch()
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }, error => {
      setTimeout(() => {
        this.loading = false;
      }, 100);

    })
  }
  openInstagramLink(event: Event) {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open(this.instagramLink);
  }
  openFacebookLink(event: Event) {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open(this.facebookLink);
  }

  openLinkedinLink(event: Event) {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open(this.linkedinLink);
  }
}
