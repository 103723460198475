<div class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="footer-copyr-logo">
                    <img src="assets/logo/p-logo-white.png" alt style="width:100px">
                    <p>© 2023 All rights reserved by Pramaangroup Events</p>
                </div>
                <div class="footer-social">
                    <ul>
                        <!-- <li><a href=""><i class="fa fa-twitter" aria-hidden="true"></i></a></li> -->
                        <li><a href="https://www.instagram.com/pramaanevents/"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                        <li><a href="https://www.facebook.com/pramaanevents"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                        <!-- <li><a href="https://www.pinterest.com/voidthemes/"><i
                                                                    class="fa fa-pinterest-p"
                                                                    aria-hidden="true"></i></a></li> -->
                        <!-- <li><a href=""><i class="fa fa-linkedin" aria-hidden="true"></i></a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-md-4">
                <div class="footer-copyr-logo">
                    <p>Quick Links</p>
                </div>
                <div class="footer-widget">
                    <!-- /.footer-widget__title -->
                    <ul class="footer-widget__links list-unstyled">
                        <li class="mega-menu"><a (click)="scrollFunction('home')">Home</a>
                        </li>
                        <li class="mega-menu"><a (click)="scrollFunction('about')" >About
                                Us</a>
                        </li>
                        <li class="mega-menu">
                            <a (click)="scrollFunction('services')">Services</a>
                        </li>
                        <li class="mega-menu">
                            <a (click)="scrollFunction('gallery')" >Gallery</a>

                        </li>
                        <li><a (click)="scrollFunction('contacts')" >Contact
                                Us</a>
                        </li>
                    </ul>
                    <!-- /.footer-widget__links -->
                </div>
            </div>
            <div class="col-md-4">
                <div class="footer-copyr-logo">
                    <!-- <img src="assets/img/logo-1.png" alt> -->
                    <p>Contact Info</p>
                </div>
                <div class="footer-widget">
                    <p>#40-5-16, DV Manor Road, Above Mahendra Jewellery Opp. F3 Manor Food
                        Plaza, Tikkle Rd, Vijayawada, Andhra Pradesh 520010</p>
                </div>
            </div>
        </div>
    </div>
</div>