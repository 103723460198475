
<header class="header-area fotolia-header header_1">
    <div class="topbar">
        <div class="container">
            <div class="row">
                <div class="col-md-7">
                    <div class="tb-contact-info">
                        <div class="phone-mail">
                            <span><i class="fa fa-phone" aria-hidden="true"></i><a style="color: white;" href="tel:9393769999">(+91)
                                    9393769999</a></span>
                            <span><i class="fa fa-envelope" aria-hidden="true"></i><a style="color: white;"
                                    href="mailto:pramaanphotography@gmail.com" class="__cf_email__"
                                    data-cfemail="6703080a060e0927060303150214144904080a">pramaanphotography@gmail.com</a></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="tb-contact-info">
                        <div class="location">
                            <span style="color: white;"><i class="fa fa-map-marker" aria-hidden="true"></i>Hyderabad and
                                Vijayawada</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="voidmega-header">
        <div class="container">
            <div class="bgvm">
                <div class="row">
                    <div class="col-12 col-xl-10">
                        <div class="vmm-header header-transparent-on vmm-mega-menu mega-menu-fullwidth header-align">
                            <div class="container">

                                <div class="vmm-header-container">

                                    <div class="logo" style="padding: 0px 10px 7px 0px;"
                                        data-mobile-logo="assets/logo/pramaan-logo.png" data-sticky-logo="assets/logo/pramaan-logo.png">
                                        <a routerLink=""><img src="assets/logo/pramaan-logo.png" style="width: 70px;"
                                                alt="logo" /></a>
                                    </div>

                                    <div class="burger-menu">
                                        <div class="line-menu line-half first-line">
                                        </div>
                                        <div class="line-menu"></div>
                                        <div class="line-menu line-half last-line">
                                        </div>
                                    </div>

                                    <nav class="vmm-menu menu-caret submenu-scale">
                                        <ul>
                                            <li class="mega-menu"><a id="home">Home</a>
                                            </li>
                                            <li class="mega-menu"><a id="about">About
                                                    Us</a>
                                            </li>
                                            <li class="mega-menu">
                                                <a routerLink="/services">Services</a>
                                            </li>
                                            <li class="mega-menu">
                                                <a routerLink="/gallery">Gallery</a>
                                               
                                            </li>
                                            <li><a routerLink="/contact">Contact
                                                    Us</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-none col-xl-2 d-xl-block">
                        <div class="search-menu-btn">
                            <div class="searchV1-btn">
                                <div class="soc-btn">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>