<header class="header-area fotolia-header header_1">
    <div class="topbar">
        <div class="container">
            <div class="row">
                <div class="col-md-7">
                    <div class="tb-contact-info">
                        <div class="phone-mail">
                            <span><i class="fa fa-phone" aria-hidden="true"></i><a style="color: white;"
                                    href="tel:9393769999">(+91)
                                    9393769999</a></span>
                            <span><i class="fa fa-envelope" aria-hidden="true"></i><a style="color: white;"
                                    href="mailto:events@pramaangroup.com" class="__cf_email__"
                                    data-cfemail="6703080a060e0927060303150214144904080a">events@pramaangroup.com</a></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="tb-contact-info">
                        <div class="location">
                            <span style="color: white;"><i class="fa fa-map-marker" aria-hidden="true"></i>Telangana & Andhra Pradesh</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="voidmega-header">
        <div class="container">
            <div class="bgvm">
                <div class="row">
                    <div class="col-12 col-xl-10">
                        <div class="vmm-header header-transparent-on vmm-mega-menu mega-menu-fullwidth header-align">
                            <div class="container">

                                <div class="vmm-header-container">

                                    <div class="logo" style="padding: 0px 10px 7px 0px;"
                                        data-mobile-logo="assets/logo/pramaan-logo.png" data-sticky-logo="assets/logo/pramaan-logo.png">
                                        <a routerLink=""><img src="assets/logo/pramaan-logo.png" style="width: 70px;"
                                                alt="logo" /></a>
                                    </div>

                                    <div class="burger-menu">
                                        <div class="line-menu line-half first-line">
                                        </div>
                                        <div class="line-menu"></div>
                                        <div class="line-menu line-half last-line">
                                        </div>
                                    </div>

                                    <nav class="vmm-menu menu-caret submenu-scale">
                                        <ul>
                                            <li class="mega-menu"><a href="#home">Home</a>
                                            </li>
                                            <li class="mega-menu"><a href="#about">About
                                                    Us</a>
                                            </li>
                                            <li class="mega-menu">
                                                <a href="#services">Services</a>
                                            </li>
                                            <li class="mega-menu">
                                                <a href="#gallery">Gallery</a>

                                            </li>
                                            <li><a href="#contacts">Contact
                                                    Us</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-none col-xl-2 d-xl-block">
                        <div class="search-menu-btn">
                            <div class="searchV1-btn">
                                <div class="soc-btn">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<app-preloader *ngIf="loading"></app-preloader>
<div *ngIf="!loading">
    <section class="hero-area hero_V1" [style.backgroundImage]="'url(' + coverimage + ')'">
        <!-- [style.backgroundImage]="'url(' + coverimage + ')'"> -->
        <div class="container">
            <div class="hero-content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="hero-text">
                            <h2 class="fadeIn" data-wow-delay=".5s"><span>{{mainTitle}}</span>
                            </h2>
                            <h4 style="color:white;font-size:4rem;line-height:2">{{name}}</h4>
                            <p class="fadeIn" data-wow-delay=".99s">{{description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<section class="portfolio-area bgDark section-padding container pb-0">
    <div class="row justify-content-center">
        <div class="col-lg-12 col-lg-offset-0 col-md-6 col-md-offset-0 col-sm-10 col-sm-offset-1 col-xs-12">
            <div id="image-frame">
                <img src="{{eventimg}}" />
                <div class="text-area">
                    <h1 style="color: white;">{{name}}</h1>
                    <p style="color: white;">{{eventdate | date:'yyyy-MM-dd'}}</p>
                    <p style="text-align: center;color: white;">{{description}}</p>
                </div>
            </div>
        </div>
        <br>
    </div>
    <br>
    <h1 class="wow fadeInUp" *ngIf="driveImages" style="text-align: center;">GALLERY</h1>
    <section class="portfolio-area portfolio-page-content bgDark section-padding pb-0">
        <div class="container">
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="core-service-1" role="tabpanel">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-4" *ngFor="let images of driveImages">
                            <figure class="single-shuffle">
                                <div class="aspect">
                                    <div class="aspect__inner ssf-content wow zoomIn" data-wow-delay=".15s">
                                        <img src="https://drive.google.com/uc?id={{images.id}}" alt />
                                        <div class="ssf-hover">
                                            <a data-fancybox="group-4" class="fancyGallery"
                                                href="https://drive.google.com/uc?id={{images.id}}">
                                                <i class="fa fa-search-plus" aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>
<div class="text-center core-service-area" style="padding: 10px;padding-top: 59px;">
    <a href="https://www.instagram.com/pramaanphotography/" class="insta-btn" style="font-size: 36px;font-weight: 500;">
        <img src="assets/assets/img/icon/instagram.png" alt>
        <span style="margin-left: 10px;color: #43435b;">INSTAGRAM</span>
        <p style="color: #43435b; font-size:18px;font-weight: 600">Highlights</p>
    </a>
</div>
<div class="wrapper">
    <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let slide of apiData">
            <ng-template class="slide" carouselSlide [id]="slide.id">
                <video *ngIf="slide.media_type === 'VIDEO'" width="285" height="288" [src]="slide.media_url" controls controlsList="nodownload"></video>
                <img *ngIf="slide.media_type === 'IMAGE' || slide.media_type === 'CAROUSEL_ALBUM'" [src]="slide.media_url" [alt]="slide.id" >
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>
<app-whatsapp></app-whatsapp>
<div class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="footer-copyr-logo">
                    <img src="assets/logo/p-logo-white.png" alt style="width:100px">
                    <p>© 2023 All rights reserved by Pramaangroup Events</p>
                </div>
                <div class="footer-social">
                    <ul>
                        <li><a [href]="instagramLink" (click)="openInstagramLink($event)"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                        <li><a [href]="facebookLink" (click)="openFacebookLink($event)"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                        <li><a [href]="linkedinLink" (click)="openLinkedinLink($event)"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-4">
                <div class="footer-copyr-logo">
                    <p>Quick Links</p>
                </div>
                <div class="footer-widget">
                    <!-- /.footer-widget__title -->
                    <ul class="footer-widget__links list-unstyled">
                        <li class="mega-menu"><a style="color: white;" href="#home">Home</a>
                        </li>
                        <li class="mega-menu"><a style="color: white;" href="#about">About
                                Us</a>
                        </li>
                        <li class="mega-menu">
                            <a style="color: white;" href="#services">Services</a>
                        </li>
                        <li class="mega-menu">
                            <a   style="color: white;" href="#gallery">Gallery</a>

                        </li>
                        <li class="mega-menu"><a style="color: white;" href="#contacts">Contact Us</a>
                        </li>
                    </ul>
                    <!-- /.footer-widget__links -->
                </div>
            </div>
            <div class="col-md-4">
                <div class="footer-copyr-logo">
                    <!-- <img src="assets/img/logo-1.png" alt> -->
                    <p>Contact Info</p>
                </div>
                <div class="footer-widget">
                    <p>Telangana : 304K, Road Num : 78, Jubilee Hills, Hyderabad - 500096 </p>
                    <p>Andhra Pradesh : #40-5-16, DV Manor Road, Above Mahendra Jewellery Opp. F3 Manor Food Plaza,Tikkle
                        Rd, Vijayawada, Andhra Pradesh 520010</p>
                   
                </div>
            </div>
        </div>
    </div>
</div>