import { HttpClient } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { data } from 'jquery';
import { OwlOptions } from 'ngx-owl-carousel-o';
// import { ToastrService } from 'ngx-toastr';
import { EventsService } from 'src/app/services/events.service';
declare var jarallax: any;


@Component({
  selector: 'app-home-eight',
  templateUrl: './home-eight.component.html',
  styleUrls: ['./home-eight.component.scss']
})
export class HomeEightComponent implements OnInit, AfterViewInit {
  @ViewChild('myElement', { static: false }) myElementRef!: ElementRef

  loader: boolean = true;
  apiData: any;
  limit: number = 10; // <==== Edit this number to limit API results
  customOptions: OwlOptions = {
    loop: true,
    margin: 20,
    dots: false,
    autoplay: true,
    slideTransition: 'linear',
    autoplaySpeed: 3000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 5
      }
    }
  }
  galleryImages: any;
  name: any;
  email: any;
  subject: any;
  phone: any;
  message: any;
  contactForm: FormGroup;
  isSubmitted = false;
  galleryData: any;
  driveGalleryImages: any;
  loading: boolean = false;
  galleryImagesData: any;
  mainHomeGallery: any = [];
  instaToken: any;
  mainVideo: any;
  mainHomeGalleryVideo: any = []
  mainVideogallery = 'mp4:assets/images/eventvideo.mp4';
  googleAuthKey: any;
  errorMessage = false;
  services: any
  successMessage: any;
  instagramLink: any;
  facebookLink: any;
  linkedinLink: any;
  constructor(private readonly http: HttpClient, private eventService: EventsService, private formBuilder: FormBuilder, private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    //  private toast: ToastrService
  ) { }
  ngOnInit(): void {
    this.getDriveImages()
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      // services: ['', Validators.required],
      phone: ['', Validators.required],
      message: ['', Validators.required]
    });
  }
  get errorControl() {
    return this.contactForm.controls;
  }
  fetch() {
    const api = `https://graph.instagram.com/me/media?fields=caption,id,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=${this.instaToken}`;
    const http$ = this.http.get(api);
    http$.subscribe(
      (res) =>
        this.apiData = res['data'],
    )
  }
  scrollFunction(e: any) {
    var scrollDiv = document.getElementById(e).offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
  }
  getDriveImages() {
    this.eventService.getDriveImagesGallery().subscribe((response: any) => {
      this.galleryData = response
      this.instagramLink = this.galleryData?.instagram_link
      this.facebookLink = this.galleryData?.facebook_link
      this.linkedinLink = this.galleryData?.linkedin_link
      this.mainVideo = this.galleryData?.event_video_link
      this.instaToken = this.galleryData?.insta_access_event
      this.driveGalleryImages = this.galleryData?.main_gallery
      const linkArray = this.driveGalleryImages.split('/')
      const id = linkArray[linkArray?.length - 1]
      this.getDriveData(id);
      this.extractFileId(this.mainVideo);
      this.fetch()
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
      , error => {
        setTimeout(() => {
          this.loading = false;
        }, 100);

      })
  }
  getDriveData(id) {
    this.eventService.getGoogleDriveImages(id).subscribe((response: any) => {
      if (response.totalCount === 0) {
        this.errorMessage = true;
      } else {
        this.errorMessage = false;
      }
      this.galleryImagesData = response[0]?.files
      for (let index = 0; index < (this.galleryImagesData.length = 9); index++) {
        const element = this.galleryImagesData[index];
        this.mainHomeGallery.push(element)
      }
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }, error => {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    })

  }
  openInstagramLink(event: Event) {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open(this.instagramLink);
  }
  openFacebookLink(event: Event) {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open(this.facebookLink);
  }

  openLinkedinLink(event: Event) {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open(this.linkedinLink);
  }
  extractFileId(url: string): string {
    const pattern = /\/file\/d\/([a-zA-Z0-9_-]+)\//;
    const match = url.match(pattern);
    if (match && match[1]) {
      this.mainVideogallery = 'mp4:https://drive.google.com/uc?id=' + match[1];
      // localStorage.setItem('upcomingvid', this.mainHomeGalleryVideo)
      this.initJarallax();
    }

    return '';
  }
  ngAfterViewInit() {
    const localVideoFeedData = localStorage.getItem('vid')
    if (localVideoFeedData) {
      this.mainVideogallery = 'mp4:https://drive.google.com/uc?id=' + localVideoFeedData
      this.initJarallax();
    }
  }

  initJarallax() {
    const myElement = this.myElementRef.nativeElement
    jarallax(myElement, {
      videoSrc: this.mainVideogallery,
      videoLoop: true,
      videoPlayOnlyVisible: true,
      videoLazyLoading: true
    });
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }
  viewMore() {
    this.router.navigateByUrl('/gallery')
  }
  sendEmail() {
    this.isSubmitted = true;
    const userObject = {
      'name': this.name,
      'email': this.email,
      'subject': this.subject,
      'phone': this.phone,
      'message': this.message,
    }
    if (this.contactForm.valid) {
      this.eventService.sendMail(userObject).subscribe((response: any) => {
        this.successMessage = response.message
        // this.toast.success("success", 'Email Sent Successfully');
        this.contactForm.reset({
          name: [''],
          email: [''],
          subject: [''],
          phone: [''],
          message: ['']
        });
      },
        (error) => {
          // Handle error
          console.error(error);
        }
      );

    }

  }
}
