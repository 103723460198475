import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { EventsService } from 'src/app/services/events.service';
import { environment } from 'src/environments/environment';
declare var jarallax: any;


@Component({
  selector: 'app-past',
  templateUrl: './past.component.html',
  styleUrls: ['./past.component.scss'],
  providers: [DatePipe]
})
export class PastComponent implements OnInit, AfterViewInit {
  @ViewChild('myElement', { static: false }) myElementRef!: ElementRef
  eventData: any;
  pastevents: any;
  currentDate = new Date();
  myDate: string;
  pastEventsDate: string;
  filtered_list = []
  apiData: any;
  limit: number = 10; // <==== Edit this number to limit API results
  customOptions: OwlOptions = {
    loop: true,
    margin: 20,
    dots: false,
    autoplay: true,
    slideTransition: 'linear',
    // autoplayTimeout: 0,
    autoplaySpeed: 3000,
    // autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 5
      }
    }
  }
  loading: boolean = false;
  galleryData: any;
  instaToken: any;
  mainVideogallery = 'mp4:assets/images/eventvideo.mp4';
  mainHomeGalleryVideo: any = []
  mainVideo: any;
  driveGalleryImages: any;
  googleAuthKey: any;
  s3UrlLink = environment.s3_URL
  errorMessage = false
  instagramLink: any;
  facebookLink: any;
  linkedinLink: any;
  eventdate: any;
  eventsObj: any;
  eventTypeList: any;
  pasteventsDropDownList: any;
  pasteventsTypeObj: any;
  pasteventsDropDown: any;
  eventListData: any;
  constructor(private eventservice: EventsService, private datePipe: DatePipe, private router: Router, private readonly http: HttpClient) { }

  ngOnInit(): void {
    this.getStaticDriveImagesGallery()
    this.getpasteventDropDown()
    this.myDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.eventservice.getPastEvents().subscribe((response: any) => {
      this.eventData = response.data
      for (let index = 0; index < this.eventData.length; index++) {
        this.pasteventsTypeObj = this.pasteventsDropDownList.find((element: any) => {
          return element.id == this.eventData[index].event_type
        });
        this.eventData[index].eventdropDownData = this.pasteventsTypeObj.name
        this.eventdate = this.datePipe.transform(response.data[index].event_date, 'yyyy-MM-dd');

        if (response.totalCount === 0) {
          this.errorMessage = true;
        } else {
          this.errorMessage = false;
        }
        this.eventData = response.data.filter((item) => {


          const itemDate = new Date(item.event_date);
          const filterDateObj = new Date();
          console.log(filterDateObj, itemDate);

          return itemDate < filterDateObj;

        });
        console.log("***", this.eventData);

        setTimeout(() => {
          this.loading = false;
        }, 100);
      }
      // , (error:any) => {
      //   setTimeout(() => {
      //     this.loading = false;
      //   }, 100);
      // }
    })
  }
  toggleCard(item) {
    this.router.navigate(['/pastevents-details', item.id]);
  }
  fetch() {
    const api = `https://graph.instagram.com/me/media?fields=caption,id,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=${this.instaToken}`;
    const http$ = this.http.get(api);
    http$.subscribe(
      (res) =>
        this.apiData = res['data'],
    )
  }


  scrollFunction(e: any) {
    var scrollDiv = document.getElementById(e).offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
  }
  getStaticDriveImagesGallery() {
    this.eventservice.getStaticDriveImagesGallery().subscribe((response: any) => {
      this.googleAuthKey = response?.google_api_key
      this.getDriveImages()
    })
  }
  getDriveImages() {
    this.eventservice.getDriveImagesGallery().subscribe((response: any) => {
      this.galleryData = response
      this.instagramLink = this.galleryData?.instagram_link
      this.facebookLink = this.galleryData?.facebook_link
      this.linkedinLink = this.galleryData?.linkedin_link
      this.mainVideo = this.galleryData?.past_events_video_link
      this.instaToken = this.galleryData?.insta_access_event
      console.log( this.galleryData?.insta_access_event,' this.galleryData?.insta_access_event this.galleryData?.insta_access_event this.galleryData?.insta_access_event')
      // this.driveGalleryImages = this.galleryData?.main_gallery
      // const linkArray = this.driveGalleryImages.split('/')
      // const id = linkArray[linkArray?.length - 1]
      // this.getMainVideo(data); 
      this.extractFileId(this.mainVideo)
      this.fetch()
      setTimeout(() => {
        this.loading = false;
      }, 100);

    }, error => {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    })
  }
  openInstagramLink(event: Event) {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open(this.instagramLink);
  }
  openFacebookLink(event: Event) {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open(this.facebookLink);
  }

  openLinkedinLink(event: Event) {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open(this.linkedinLink);
  }
  extractFileId(url: string): string {
    const pattern = /\/file\/d\/([a-zA-Z0-9_-]+)\//;
    const match = url.match(pattern);

    if (match && match[1]) {
      this.mainVideogallery = 'mp4:https://drive.google.com/uc?id=' + match[1];
      this.initJarallax();
    }
    return '';
  }

  ngAfterViewInit() {
    const localVideoFeedData = localStorage.getItem('pastvid')
    if (localVideoFeedData) {
      this.mainVideogallery = 'mp4:https://drive.google.com/uc?id=' + localVideoFeedData
      this.initJarallax();
    }

  }
  getpasteventDropDown() {
    this.eventservice.geteventtypeDropDown().subscribe((response: any) => {
      this.pasteventsDropDownList = response

    })
  }
  initJarallax() {
    const myElement = this.myElementRef.nativeElement
    jarallax(myElement, {
      videoSrc: this.mainVideogallery,
      videoLoop: true,
      videoPlayOnlyVisible: true,
    });
    this.loading = false;
  }


}
function getEventtype() {
  throw new Error('Function not implemented.');
}

