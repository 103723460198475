import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/home-one/home-one.component';
import { HomeTwoComponent } from './components/home-two/home-two.component';
import { HomeThreeComponent } from './components/home-three/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { HomeFiveComponent } from './components/home-five/home-five.component';
import { HomeSixComponent } from './components/home-six/home-six.component';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { HomeEightComponent } from './components/home-eight/home-eight.component';
import { HomeNineComponent } from './components/home-nine/home-nine.component';
import { HomeTenComponent } from './components/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/home-twelve/home-twelve.component';
import { AboutComponent } from './components/about/about.component';
import { ServicesComponent } from './components/services/services.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { ContactComponent } from './components/contact/contact.component';
import { PastComponent } from './components/past/past.component';
import { UpcomingComponent } from './components/upcoming/upcoming.component';
import { PasteventDetailsComponent } from './pastevent-details/pastevent-details.component';
import { WhatsappComponent } from './components/whatsapp/whatsapp.component';
import { AllImagesComponent } from './components/all-images/all-images.component';
const routes: Routes = [
    { path: '', component: HomeEightComponent, },
    // { path: 'home-two', component: HomeTwoComponent },
    // { path: 'home-three', component: HomeThreeComponent },
    // { path: 'home-four', component: HomeFourComponent },
    // { path: 'home-five', component: HomeFiveComponent },
    // { path: 'home-six', component: HomeSixComponent },
    // { path: 'home-seven', component: HomeSevenComponent },
    // { path: 'home-eight', component: HomeEightComponent },
    // { path: 'home-nine', component: HomeNineComponent },
    // { path: 'home-ten', component: HomeTenComponent },
    // { path: 'home-eleven', component: HomeElevenComponent },
    // { path: 'home-twelve', component: HomeTwelveComponent },
    { path: 'about', component: AboutComponent },
    // { path: 'services', component: ServicesComponent },
    { path: 'gallery', component: GalleryComponent },
    // { path: 'contact', component: ContactComponent },
    { path: 'past-events', component: PastComponent },
    { path: 'upcoming-events', component: UpcomingComponent },
    { path: 'pastevents-details/:id', component: PasteventDetailsComponent},
    {path:'whatsapp',component:WhatsappComponent},
    {path:'all-images',component:AllImagesComponent},
    {path:'all-images/:id',component:AllImagesComponent}

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }