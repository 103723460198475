<header class="header-area fotolia-header header_1">
    <div class="topbar">
        <div class="container">
            <div class="row">
                <div class="col-md-7">
                    <div class="tb-contact-info">
                        <div class="phone-mail">
                            <span><i class="fa fa-phone" aria-hidden="true"></i><a style="color: white;"
                                    href="tel:9393769999">(+91)
                                    9393769999</a></span>
                            <span><i class="fa fa-envelope" aria-hidden="true"></i><a style="color: white;"
                                    href="mailto:pramaanphotography@gmail.com" class="__cf_email__"
                                    data-cfemail="6703080a060e0927060303150214144904080a">pramaanphotography@gmail.com</a></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="tb-contact-info">
                        <div class="location">
                            <span style="color: white;"><i class="fa fa-map-marker" aria-hidden="true"></i>Hyderabad and
                                Vijayawada</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="voidmega-header">
        <div class="container">
            <div class="bgvm">
                <div class="row">
                    <div class="col-12 col-xl-10">
                        <div class="vmm-header header-transparent-on vmm-mega-menu mega-menu-fullwidth header-align">
                            <div class="container">

                                <div class="vmm-header-container">

                                    <div class="logo" style="padding: 0px 10px 7px 0px;"
                                        data-mobile-logo="assets/logo/logo.png" data-sticky-logo="assets/logo/logo.png">
                                        <a routerLink=""><img src="assets/logo/logo.png" style="width: 70px;"
                                                alt="logo" /></a>
                                    </div>

                                    <div class="burger-menu">
                                        <div class="line-menu line-half first-line">
                                        </div>
                                        <div class="line-menu"></div>
                                        <div class="line-menu line-half last-line">
                                        </div>
                                    </div>

                                    <nav class="vmm-menu menu-caret submenu-scale">
                                        <ul>
                                            <li class="mega-menu"><a href="#home">Home</a>
                                            </li>
                                            <li class="mega-menu"><a href="#about">About
                                                    Us</a>
                                            </li>
                                            <li class="mega-menu">
                                                <a href="#services">Services</a>
                                            </li>
                                            <li class="mega-menu">
                                                <a href="#gallery">Gallery</a>

                                            </li>
                                            <li><a href="#contact">Contact
                                                    Us</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-none col-xl-2 d-xl-block">
                        <div class="search-menu-btn">
                            <div class="searchV1-btn">
                                <div class="soc-btn">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<section class="hero-area hero_V1" style="background-image: url(../../../assets/img/slides/services.webp);">
    <div class="container">
        <div class="hero-content">
            <div class="row">
                <div class="col-md-12">
                    <div class="hero-text">
                        <h2 class="fadeIn" data-wow-delay=".5s"><span>Services
                            </span>
                        </h2>
                        <p class="fadeIn" data-wow-delay=".99s">People make events into stories. Stories give events meaning</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="service-details-area bgDark section-padding">
    <div class="container">
        <!-- <div class="row">
            <div class="col-md-12">
                <div class="section-titleV1">
                    <h3 style="color:black">Events</h3>
                </div>
            </div>
        </div> -->
        <div class="service-details-content">
            <div class="row">
                <div class="col-md-7">
                    <div class="sdc-img">
                        <a><img src="assets/images/events/main.jfif" alt></a>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="sdc-text-img">
                        <h4 style="color:black">Wedding Events</h4>
                        <p style="color:black">Your wedding dreams & anticipations can be cast into real-life grand experiences and that’s what Pramaangroup does for you! Right from picking that special place to ideating designs to adorn that space. From gathering your kins & buddies to taking care of all their needs.
                            From styling you for every custom to handling the details of every rasam. 
                        </p>
                        <!-- <p style="color:black">We are here to curate and capture a picture-perfect wedding shebang for you.
                            So you can toast to new beginnings in high-spirits & live the wedding -emotion, is the reason why Pramaangroup exists; to manifest your dream-days with sheer dedication.</p> -->
                        <div class="sdci">
                            <div class="row">
                                <div class="col-md-6">
                                    <a> <img src="assets/images/events/1.jfif" alt></a>
                                </div>
                                <div class="col-md-6">
                                    <a> <img src="assets/images/events/2.webp" alt></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5">
                    <div class="sdc-text-img">
                        <h4 style="color:black">Birthday Events</h4>
                        <p style="color:black">Your birthday dreams & anticipations can be cast into real-life grand experiences and that’s what Pramaangroup does for you! Right from picking that special place to ideating designs to adorn that space. From gathering your kins & buddies to taking care of all their needs.
                            From styling you for every custom to handling the details of every rasam. 
                        </p>
                        <!-- <p style="color:black">We are here to curate and capture a picture-perfect wedding shebang for you.
                            So you can toast to new beginnings in high-spirits & live the wedding -emotion, is the reason why Pramaangroup exists; to manifest your dream-days with sheer dedication.</p> -->
                        <div class="sdci">
                            <div class="row">
                                <div class="col-md-6">
                                    <a> <img src="assets/images/events/b1.jpeg" alt></a>
                                </div>
                                <div class="col-md-6">
                                    <a> <img src="assets/images/events/b2.jpg" alt
                                            style="height:140px"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="sdc-img">
                        <a> <img src="assets/images/events/birthday.avif" alt></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="service-details-content">
            <div class="row">
                <div class="col-md-7">
                    <div class="sdc-img">
                        <a> <img src="assets/img/halfsaree/1.jpg" alt></a>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="sdc-text-img">
                        <h4 style="color:black">Halfsaree Events</h4>
                        <p style="color:black">Your halfsaree ceremony dreams & anticipations can be cast into real-life grand experiences and that’s what Pramaangroup does for you! Right from picking that special place to ideating designs to adorn that space. From gathering your kins & buddies to taking care of all their needs.
                            From styling you for every custom to handling the details of every rasam. 
                        </p>
                        <!-- <p style="color:black">We are here to curate and capture a picture-perfect wedding shebang for you.
                            So you can toast to new beginnings in high-spirits & live the wedding -emotion, is the reason why Pramaangroup exists; to manifest your dream-days with sheer dedication.</p> -->
                        <div class="sdci">
                            <div class="row">
                                <div class="col-md-6">
                                    <a> <img src="assets/img/halfsaree/2.jpeg" alt></a>
                                </div>
                                <div class="col-md-6">
                                    <a> <img src="assets/img/halfsaree/3.webp" alt
                                            style="height: 155px;"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                            <div class="col-md-5">
                                    <div class="sdc-text-img">
                                            <h4>Political Events</h4>
                                            <p>Photography is like a moment, an instant. You need a
                                                    half-second
                                                    the photo. So it's good to capture people when they are
                                                    thems It
                                                    takes a lot of imagination to be a good photographer.
                                            </p>
                                            <p>But in photography everything is so ordinary; it takes a lot
                                                    looking
                                                    you learn to see the extraordinary. Photography like
                                                    moment.</p>
                                            <div class="sdci">
                                                    <div class="row">
                                                            <div class="col-md-6">
                                                                    <a href="political.html"> <img
                                                                                    src="images/corporate/1.jpg"
                                                                                    alt></a>
                                                            </div>
                                                            <div class="col-md-6">
                                                                    <a href="political.html"> <img
                                                                                    src="images/corporate/2.jpg"
                                                                                    alt
                                                                                    style="height:140px"></a>
                                                            </div>
                                                    </div>
                                            </div>
                                    </div>
                            </div>
                            <div class="col-md-7">
                                    <div class="sdc-img">
                                            <a href="political.html"> <img src="images/corporate/3.jpg"
                                                            alt></a>
                                    </div>
                            </div>
                    </div> -->

        </div>
    </div>
</section>

<section class="instagram-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 no-pad">
                <div class="insta-slider">
                    <div class="insta-carousel owl-carousel owl-theme">
                        <div class="item">
                            <div class="single-insta-img">
                                <img src="assets/assets/img/slider/instagram/insta-1.jpg" alt>
                            </div>
                        </div>
                        <div class="item">
                            <div class="single-insta-img">
                                <img src="assets/assets/img/slider/instagram/insta-2.jpg" alt>
                            </div>
                        </div>
                        <div class="item">
                            <div class="single-insta-img">
                                <img src="assets/assets/img/slider/instagram/insta-3.jpg" alt>
                            </div>
                        </div>
                        <div class="item">
                            <div class="single-insta-img">
                                <img src="assets/assets/img/slider/instagram/insta-4.jpg" alt>
                            </div>
                        </div>
                        <div class="item">
                            <div class="single-insta-img">
                                <img src="assets/assets/img/slider/instagram/insta-5.jpg" alt>
                            </div>
                        </div>
                        <div class="item">
                            <div class="single-insta-img">
                                <img src="assets/assets/img/slider/instagram/insta-6.jpg" alt>
                            </div>
                        </div>
                        <div class="item">
                            <div class="single-insta-img">
                                <img src="assets/assets/img/slider/instagram/insta-7.jpg" alt>
                            </div>
                        </div>
                        <div class="item">
                            <div class="single-insta-img">
                                <img src="assets/assets/img/slider/instagram/insta-8.jpg" alt>
                            </div>
                        </div>
                        <div class="item">
                            <div class="single-insta-img">
                                <img src="assets/assets/img/slider/instagram/insta-5.jpg" alt>
                            </div>
                        </div>
                    </div>
                    <div class="instagram-btn">
                        <a href="https://www.instagram.com/voidcoders/" class="insta-btn">
                            <img src="assets/assets/img/icon/instagram.png" alt>
                            <span>Instagram_Feed</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>