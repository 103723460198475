import { Component } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent {
  scrollFunction(e: any) {
    var scrollDiv = document.getElementById(e).offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
  }
}
