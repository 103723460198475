import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import * as $ from 'jquery';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { HomeOneComponent } from './components/home-one/home-one.component';
import { HomeTwoComponent } from './components/home-two/home-two.component';
import { HomeThreeComponent } from './components/home-three/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { HomeFiveComponent } from './components/home-five/home-five.component';
import { HomeSixComponent } from './components/home-six/home-six.component';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { HomeEightComponent } from './components/home-eight/home-eight.component';
import { HomeNineComponent } from './components/home-nine/home-nine.component';
import { HomeTenComponent } from './components/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/home-twelve/home-twelve.component';
import { AboutComponent } from './components/about/about.component';
import { ServicesComponent } from './components/services/services.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { ContactComponent } from './components/contact/contact.component';
import { UpcomingComponent } from './components/upcoming/upcoming.component';
import { PastComponent } from './components/past/past.component';
import { HttpClientModule } from '@angular/common/http';
import { PasteventDetailsComponent } from './pastevent-details/pastevent-details.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WhatsappComponent } from './components/whatsapp/whatsapp.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { AllImagesComponent } from './components/all-images/all-images.component';
// import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    HomeEightComponent,
    AppComponent,
    PreloaderComponent,
    // HomeOneComponent,
    // HomeTwoComponent,
    // HomeThreeComponent,
    // HomeFourComponent,
    // HomeFiveComponent,
    // HomeSixComponent,
    // HomeSevenComponent,
    // HomeEightComponent,
    // HomeNineComponent,
    // HomeTenComponent,
    // HomeElevenComponent,
    // HomeTwelveComponent,
    AboutComponent,
    ServicesComponent,
    HeaderComponent,
    FooterComponent,
    GalleryComponent,
    ContactComponent,
    UpcomingComponent,
    PastComponent,
    PasteventDetailsComponent,
    WhatsappComponent,
    SpinnerComponent,
    AllImagesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CarouselModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    // ToastrModule.forRoot({
    //   closeButton: true,
    //   positionClass: 'toast-top-center',
    //   // preventDuplicates: true,
    //   timeOut: 15000, // 15 seconds
    //   progressBar: true,
    // }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
